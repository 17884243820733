import Chat from './components/Chat';
import Navigation from './components/Navigation';

function App() {
  return (
    <>
      
      <Chat />
    </>
  );
}

export default App;
