import React, { useState, useRef, createRef, useMemo, useEffect } from 'react';
import { flushSync } from 'react-dom';
import '../assets/css/Chat.css'
import { RotatingLines } from 'react-loader-spinner';
import { debounce } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { InlineAutocomplete } from 'react-inline-autocomplete';
import 'react-inline-autocomplete/dist/index.css';
import { bookmarkIcon } from '../icons/BookmarkIcon';
import { citeIcon } from '../icons/CiteIcon';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';

const endpoint = process.env.API_ENDPOINT || 'https://lass-kg-api.demos.dice-research.org';

const nPapers = process.env.N_PAPERS || 8;
const ABSTRACT_LEN = process.env.REACT_APP_SNIPPET_LENGTH || 500;
const NUMBER_OF_SUGGESTIONS = 10;

const copyToClipboard = async (doi) => {
  const url = endpoint + '/common/bibtex?doi=' + doi;

  try {
    // Create an AbortController to handle timeout
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 5000); // 5 seconds timeout

    const response = await fetch(url, {
      method: "GET",
      signal: controller.signal, // Attach the AbortController signal
    });

    clearTimeout(timeoutId); // Clear the timeout if the request completes in time

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const responseData = await response.json();
    navigator.clipboard.writeText(responseData["bibtex"]).then(() => {
      console.log('Text copied to clipboard' + responseData["output"]);
      alert('Bibtex copied to clipboard');
    }).catch((err) => {
      console.error('Could not copy text: ', err);
    });
    return responseData["output"];
  } catch (error) {
    console.error(error);
    return "No bibtex found";
  }
};

const savePaperToBookmark = (doi) => {
  console.log('Save doi to bookmark:', doi);
};

export default function Chat() {
    const { t } = useTranslation();
    const [inputText, setInputText] = useState('');
    const [completions, setCompletions] = useState([]);
    const [suggestions, setSuggestions] = useState(null);
    const [loading, setLoading] = useState(false);
    const [messages, setMessages] = useState([]);
    const [leftoverMessages, setLeftoverMessages] = useState([]);
    const [sessionId, setSessionId] = useState('');
    const sessionIdRef = useRef('');
    const messagesRef = useRef(null);
    const inputRef = createRef(null);

    const fetchDefaultSuggestions = async () => {
      try {
        const result = await fetch(endpoint + '/v2/suggest?n=' + NUMBER_OF_SUGGESTIONS);
        const data = await result.json();
        if (data.suggestions && data.suggestions?.length > 0) {
          setSuggestions(data.suggestions);
        }
      } catch (error) {
        console.error('Error fetching default suggestions:', error);
      }
    };
  

    useEffect(() => {
      if (suggestions === null) {
        fetchDefaultSuggestions();
      }
    }, [suggestions]);

    const firstMessage = useMemo(() => {
      return t('first_message');
    }, [t]);

    const sliderSettings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
      arrows: true,
      draggable: true,
      swipeToSlide: true,
      touchMove: true,
    };
    
    // useEffect for setting the session ID/sessionIdRef.current
    useEffect(() => {
      if (!sessionIdRef.current) {
        const newSessionId = uuidv4();
        setSessionId(newSessionId);
        sessionIdRef.current = newSessionId;
        console.log('Session ID created:', newSessionId); // Debugging log
      }
      }, []);

    useEffect(() => {
        console.log('Leftover messages updated:', leftoverMessages);
      }, [leftoverMessages]);

    useEffect(() => {
      setMessages((messages) => {
        if (messages.length === 0) {
          return [{ inputText: firstMessage, isUser: false }];
        } else {
          // Only update the first message when the language changes
          const updatedMessages = [...messages];
          updatedMessages[0].inputText = firstMessage;
          return updatedMessages;
        }
      });
      
    }, [firstMessage]);
  
    const handleChange = (event) => {
      setInputText(event);
    };

    const showMorePapers = () => {
      flushSync(() => {
        console.log("Leftover messages: " + leftoverMessages)
        const newMessages = leftoverMessages.slice(0, nPapers);
        const filteredNewMessages = newMessages.filter((message) => message.content);
        setLeftoverMessages(leftoverMessages.slice(nPapers));
        setMessages((messages) => [
          ...messages,
          ...filteredNewMessages.map((paper) => {
            const pdfLink = paper.pdfUrl !== "none" ? ` · <a target='_blank' rel='noopener noreferrer' href='${paper.pdfUrl}'>PDF</a>` : '';
            return {
              inputText: `
              <div>
                <b><a target='_blank' rel='noopener noreferrer' href='${paper.url}'>${paper.title}</a></b><br><br>
                <div class='snippet'>
                  <span>${(paper.content?.length > ABSTRACT_LEN ? paper.content.substring(0, ABSTRACT_LEN) + " ..." : paper.content)}</span>
                </div><br>
                <div style="display: flex; justify-content: space-between;">
                  <div style="width: 80%;">
                    ${paper.displayDate} · <a target='_blank' rel='noopener noreferrer' href='https://www.doi.org/${paper.identifier}'>DOI</a> · ${paper.creators[0]} et al.${pdfLink} · <span class="bold">${paper.citations}</span> citations
                    </div>
                  <div style="width: 20%; text-align: right;">
                    <span id='cite-icon' onclick="copyToClipboard('${paper.identifier}')">
                      ${citeIcon}
                    </span>
                    <span id='bookmark-icon' onclick="savePaperToBookmark('${paper.identifier}')">
                      ${bookmarkIcon}
                    </span>
                  </div>
                </div>
              </div>`,
              isUser: false,
            }
          })
        ]);

        if (leftoverMessages.length === 0) {
          const loadMoreButton = messagesRef.current?.querySelector('.message.center');
          loadMoreButton?.remove();
        }
      });
    }
  
    const fetchData = async (input) => {
      setLoading(true);
      const query_result = await fetch(endpoint + '/v2/agent-search?query=' + input + '&session_id=' + sessionId, {
        method: 'POST',
      });
      const data = await query_result.json();
      setLoading(false);
      return data;
    };
  
    const onSendClick = async (e) => {
      e.preventDefault(); // prevent page reload
  
      if (!inputText) {
        alert('Input is empty!');
        return; // exit the function if input is empty
      }
  
      const input = inputText;
  
      setInputText(''); // clear the input
       // this function waits until the DOM is updated
      flushSync(() => {
         // set messages
        setMessages((messages) => [
          ...messages,
          { inputText: input, isUser: true },
        ]);

        setLeftoverMessages([]); 
      });
  
      messagesRef.current?.lastElementChild?.scrollIntoView({
        behavior: 'smooth',
      }); // scroll to the bottom of the chat
      // send POST request to the server
      let result = await fetchData(input);
      const answer = result.answer + '<br><br>' + result.html_substance_property;
      const papers = result.abstracts.map((abstract) => ({
        content: abstract.content,
        identifier: abstract.identifier,
        creators: abstract.creators,
        title: abstract.title,
        citations: abstract.citations,
        displayDate: new Date(abstract.publicationDate).getFullYear(),
        url: abstract.url,
        pdfUrl: abstract.pdfUrl
      }))
      console.log('Papers:', papers);
      let leftoverMessagesTmp = [];

      flushSync(() => {
        
        const filteredPapers = papers.filter((paper) => paper.content !== null).slice(0, nPapers);
        leftoverMessagesTmp = papers.slice(nPapers);
        setMessages((messages) => [
          ...messages,
          { inputText: answer, isUser: false },
          ...filteredPapers.map((paper) => {
            const pdfLink = paper.pdfUrl !== "none" ? ` · <a target='_blank' rel='noopener noreferrer' href='${paper.pdfUrl}'>PDF</a>` : '';
            return {
              inputText: `
                <div>
                <b><a target='_blank' rel='noopener noreferrer' href='${paper.url}'>${paper.title}</a></b><br><br>
                <div class='snippet'>
                  <span>${(paper.content?.length > ABSTRACT_LEN ? paper.content.substring(0, ABSTRACT_LEN) + " ..." : paper.content)}</span>
                </div><br>
                <div style="display: flex; justify-content: space-between;">
                  <div style="width: 60%;">
                    ${paper.displayDate} · <a target='_blank' rel='noopener noreferrer' href='https://www.doi.org/${paper.identifier}'>DOI</a> · ${paper.creators[0]} et al.${pdfLink} · <span class="bold">${paper.citations}</span> citations
                    </div>
                  <div style="width: 40%; text-align: right;">
                    <span class='cite-icon' onclick="copyToClipboard('${paper.identifier}')">
                      ${citeIcon}
                    </span>  
                    <span id='bookmark-icon' onclick="savePaperToBookmark('${paper.identifier}')">
                      ${bookmarkIcon}
                    </span>
                  </div>
                </div>
                <div>
              `,
              isUser: false,
            }
          })
        ]);
      });

      setLeftoverMessages((messages) => [
        ...messages,
        ...leftoverMessagesTmp
      ]);

      await new Promise((resolve) => setTimeout(resolve, 0)); // Wait for the DOM to update

      const userMessages = messagesRef.current?.querySelectorAll('.messages > .message.right.appeared');
      const lastUserMessage = userMessages[userMessages.length - 1];
      const firstBotMessage = lastUserMessage?.nextElementSibling;

      firstBotMessage?.scrollIntoView({
        behavior: 'smooth',
      });

      result = await fetch(endpoint +'/v2/suggest?query=' + input + '&n=' + NUMBER_OF_SUGGESTIONS);
      result = await result.json();
      if (result.suggestions != null && result.suggestions.length > 0) {
        setSuggestions(result.suggestions);
      }
    };
    
    // Modified getAutoCompletions function to use POST request
    const getAutoCompletions = async (input) => {
      const value = input;
      //e.preventDefault() // prevent page reload
  
      if (value != null && value.length > 0) {
        const sessionId = sessionIdRef.current;
        if (!sessionId) {
          console.error('Session ID is not set');
          return;
        }
        console.log('Making request with Session ID:', sessionId); // Debugging log

        let result = await fetch(endpoint + '/v2/autocomplete?query=' + value, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Session-Id': sessionId,
          },
        });
        console.log('Session ID sent in request:', sessionId); // Debugging log
        
        result = await result.json();
        if (result.completion != null && result.completion.length > 0) {
          setCompletions((completions) => [
            {
              text: result.completion,
              value: result.completion,
            },
            ...completions,
          ]);
          console.log('Compleiton: ' + result.completion);
        } else {
          //setCompletions([])
          console.log('no completion');
        }
      } else {
        setCompletions([]);
      }
    };
    
    const handleSubmit = async (e) => {
      e.preventDefault();
      const sessionId = sessionIdRef.current;
      if (!sessionId) {
        console.error('Session ID is not set');
        return;
      }
    
      console.log('Making request with Session ID:', sessionId); // Debugging log
    
      let result = await fetch(endpoint + '/api/data', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Session-Id': sessionId,
        },
        body: JSON.stringify({ query: inputText }),
      });
    
      result = await result.json();
      console.log('Response:', result);
    };

    //Input key press event handler
    const onInputKeyPress = (e) => {
      if (e.keyCode === 13) {
        onSendClick(e);
      }
    };
  
    const onSuggestionsClick = (e) => {
      setInputText(e.target.value);
      setSuggestions([]);
    };
  
    const debouncedFetch = useMemo(() => debounce(getAutoCompletions, 300), []);
  
    useEffect(() => {
      window.copyToClipboard = copyToClipboard;
      window.savePaperToBookmark = savePaperToBookmark;
      debouncedFetch(inputText);
      // eslint-disable-next-line
    }, [inputText]);
  

  return (
    <div className='container' style={{ pointerEvents: loading ? 'none' : 'auto', opacity: loading ? 0.5 : 1 }}>
      <div className='row'>
        <div className='col col-lg-12 chat-window' style={{ paddingLeft: 0 }}>
          <div className='top-menu'>
            <div className='top-wrapper'>
              <div className='title'>
                <img
                  alt='Springer Materials logo'
                  className='logo'
                  src={
                    process.env.PUBLIC_URL + '/img/Springer_Materials_logo.svg'
                  }
                />{' '}
                 {t('guided_search')}
              </div>
            </div>
            {loading && 
                    <div className="loader-container">
                      <div className="loader">
                        <RotatingLines strokeColor="grey" strokeWidth="5" animationDuration="0.75" width="96" visible={true} />
                      </div>
                      <p className="loader-text">{t('processing_your_question')}</p>
                    </div>
             }
            <ul ref={messagesRef} className='messages'>
              {messages.map((message, index) => {
                return (
                  <li
                    key={index}
                    className={
                      message.isUser
                        ? 'message right appeared'
                        : 'message left appeared'
                    }
                  >
                    <div className='text-wrapper'>
                      <div
                        className='text'
                      >
                        <span dangerouslySetInnerHTML={{__html: message.inputText}}></span>
                      </div>
                    </div>
                    {!message.isUser && <hr />}
                  </li>
                );
              })}
              {leftoverMessages.length > 0 && (
                <li className='message center appeared'>
                  <button className='text-wrapper btn-suggestion' onClick={showMorePapers}>
                    <div className='text'><span>Click to load more papers</span></div>
                  </button>
                  <hr />
                </li>
              )}
            </ul>
            <div
              className='bottom-wrapper bw-chat clearfix'
              style={{ display: 'block' }}
              onKeyUp={onInputKeyPress}
            >
              <div className='message-input-wrapper'>
                 {/*<input ref={inputRef} className="message-input" placeholder="Ethanol boiling point, phase diagram" onChange={onInputChange} value={inputText}/>*/}

                <InlineAutocomplete
                  ref={inputRef}
                  className='message-autocomplete'
                  style={{
                    border: 'none',
                    height: '100%',
                    boxSizing: 'border-box',
                    width: 'calc(100% - 40px)',
                    position: 'absolute',
                    outlineWidth: 0,
                    paddingLeft: '11px',
                    paddingTop: 0,
                    fontSize: '16px',
                  }}
                  value={inputText}
                  dataSource={completions}
                  onChange={handleChange}
                  caseSensitive={false}
                ></InlineAutocomplete>
              </div>
              <button className='send-message' onClick={onSendClick}>
                <div className='icon'></div>
                <div className='text'></div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='row' style={{ paddingLeft: '2vh', paddingRight: '2vh' }}>
        <div className='col col-lg-12' style={{ marginTop: '30px' }}>
          {suggestions && suggestions.length > 0 && (
            <Slider {...sliderSettings}>
              {suggestions.map((suggestion, index) => {
                return (
                  <div key={index}>
                    <button
                      className='btn btn-primary btn-suggestion'
                      value={suggestion}
                      onClick={onSuggestionsClick}
                      title={t('use_suggestion')} 
                    >
                      {suggestion}
                    </button>
                  </div>
                );
              })}
         </Slider>
         )}
        </div>
      </div>
    </div>
  );
}